.sideways-text {
    color:#F9F7EC;
    font-size: 20px;
    position: absolute;
    font-variant: small-caps;
    writing-mode: vertical-rl;
    text-orientation:mixed;
    margin-left: 25px;
}

#zoom-slider-container{
    height: 300px;
}