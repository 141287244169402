.site-title-main {
    text-decoration: underline;
}

.site-title > h1 {
    cursor: pointer;
}

@media(hover:hover){
    .site-title > h1:hover {
        color:#F9F7EC;
        text-shadow: 0px 0px 5px #aaa;
    }
}

@media (pointer: none), (pointer: coarse){
    #zoom-slider {
        display: none;
    }
}

@media (pointer: fine){
    #zoom-slider {
        display: block;
    }
}

@media screen and (orientation: portrait){
    
    header h1 {
        padding: 1.25rem 0 0.5rem 0.5rem;
    }
    
    header h2 {
        padding-left: 0.5rem;
        padding-bottom: 1rem;
        margin-top: -0.5rem;
    }

    #item-info {
        position: relative;
        display: block;
        height: calc(100vh - 20rem);
        width: 90vw;
        margin: auto;
    }

    
}

/* #article-toggle {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 3rem;
    background-color: #333;
    border: solid thin #111;
    width: 3rem;
    height: 2.5rem;
    text-align: center;
    border-radius: 0.5rem;
} */

#article-toggle span {
    position: relative;
    top: -0.75rem;
}

@media screen and (orientation: landscape){
    header h1 {
        position: fixed;
        top: 1rem;
        left: 2.5rem;
    }
    
    header h2 {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
    }

    #item-info {
        position:fixed;
        top: 3rem;
        height:calc(100% - 8rem);
        width: 20rem;
        margin-top: 2rem;
    }

    #article-toggle {
        /* display: none; */
    }
}



header, footer {
    font-family: 'Economica', sans-serif;
    color: #333;
}

header h1 {
    font-size: 2.5rem;
    margin: 0;
}

header h2 {
    font-size: 1.5rem;
}

header .version {
    font-size: 1rem;
}

#item-info {
    background-color: #333;
    padding: 1rem;
    color: #F9F7EC;
    font-family: 'Roboto', sans-serif;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

#item-info-title {
    text-align: center;
    font-size: 2rem;
    padding-top: 0.25rem;
    font-family: 'Alegreya Sans SC', 'Roboto', sans-serif;
    font-variant: small-caps;
    padding-bottom: 0.5rem;
}

.vr {
    width: 100%;
    height: 100%;
    border-left: 3px solid #443;
}

.item-article hr {
    width: 100%;
    margin-top: 0.25rem;
    border: 1px solid #aaa;
}

.item-article {
    overflow-y: auto;
}

#zoom-slider {
    position: fixed;
    right: 0;
    top: calc(50% - 150px);
    background-color: #333;
    padding:20px;
}