@media screen and (max-width:1000px){
  html{
    font-size: 15px;
  }
}

@media screen and (min-width:1000px){
  html{
    font-size: 20px;
  }
}

html {
  padding: env(safe-area-inset);
  /* font-size: 20px; */
}

* {
  margin-block-start: 0;
  margin-block-end: 0;
}

html, body {
  margin: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #48D9BE;
  padding: 0;
}