.birth-death {
    padding: 15px;
    text-align: center;
    font-size: 15px;
}

.chips {
    text-align: center;
}

.chips > * {
    margin: 3px;
}